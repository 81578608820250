import { getAccessToken, updateIgnition } from "../lib/axle";
import { getValue, setValue } from "../lib/cookies";

// External dependencies
import _, { get, isNil } from "lodash";
import { nanoid } from "nanoid";
import { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import Loader from "../components/Loader";
import { constructUriWithSearchParams } from "../lib/utility";
import { SUCCESS_SUBHEADER } from "../constants";

const Success = ({
  step,
  accountInfo,
  policyInfo,
  session,
  client,
  loginInformation,
  posthog,
  nextStep,
  rememberMe
}) => {
  const [isRedirectEnabled, setRedirectEnabled] = useState(
    session.redirectUri || session.origin
  );

  // Get login result from state
  const result = _(loginInformation).get("result");
  const resultDetail = _(loginInformation).get("resultDetail");

  useEffect(() => {
    posthog.capture("$pageview", {
      step,
      carrier: get(loginInformation, "carrier.id"),
      result: result,
    });
    const success = async () => {
      try {
        // Ensure rememberMe is properly defined (either true or false)
        // This is not strictly necessary, as rememberMe defaults to false, but better safe than sorry when it comes to async
        const resolvedRememberMe = rememberMe === undefined ? false : rememberMe;

        const ruxToken = getValue("rux");

        // Get auth code for session and account
        const { authCode, ruxTokenData, error: authCodeError } = await getAccessToken(
          accountInfo.id,
          [policyInfo.id], // Adding single policyId to array, to update when supporting multiple policies
          client.id,
          session.id,
          resolvedRememberMe,
          ruxToken
        );

        // If rux token data is returned and rememberMe is true, set the cookie and local storage
        if (ruxTokenData && resolvedRememberMe) {
          try {
            const { token, options } = ruxTokenData;

            // Use the setValue function to set both cookie and local storage
            setValue("rux", token, options);
            console.log("Remember me token set successfully in cookie and local storage");
          } catch (error) {
            console.error("Failed to set remember me token:", error);
          }
        }

        if (authCodeError) {
          switch (get(authCodeError, "code")) {
            case 500:
              console.log(JSON.stringify(authCodeError));
              nextStep("axle-error");
              return;
            default:
              nextStep("connection-error");
              return;
          }
        }

        if (isNil(authCode)) {
          // will be true if authCode is null or undefined
          console.error("authCode is null or undefined");
          nextStep("axle-error");
          return;
        }

        // Construct webhook event
        const lastEvent = {
          id: `event_${nanoid()}`,
          type: `ignition.completed`,
          data: {
            token: session.id,
            authCode,
            result,
            resultDetail,
            user: session.user,
            client: client.id,
          },
          createdAt: new Date().toISOString(),
        };

        // Update Ignition session
        const params = {
          status: "completed",
          lastEvent,
          result,
          resultDetail,
          rememberMe: resolvedRememberMe,
        };
        const [updateIgnitionResponse, updateIgnitionError] =
          await updateIgnition(session.id, params);

        if (updateIgnitionError) {
          switch (get(updateIgnitionError, "code")) {
            case 500:
              console.log(JSON.stringify(updateIgnitionError));
              nextStep("axle-error");
              return;
            default:
              nextStep("connection-error");
              return;
          }
        }

        if (authCode && isRedirectEnabled) {
          await sendEventAndRedirect(authCode);
        }
      } catch (error) {
        console.error(error);
      }
    };
    trackPromise(success());
  }, [posthog]);

  const sendEventAndRedirect = async (authCodeParam) => {
    // Create params for postMessage or redirectUri
    const paramsToAdd = {
      status: "complete",
      authCode: authCodeParam,
      client: client.id,
      result,
    };

    // Send message to parent window if initialized in an iframe
    if (session.origin) {
      window.parent.postMessage(paramsToAdd, session.origin);
      console.log(
        "Message sent to parent window",
        JSON.stringify(paramsToAdd, null, 2)
      );
    }

    if (session.redirectUri) {
      const constructedUri = constructUriWithSearchParams(
        session.redirectUri,
        paramsToAdd
      );

      window.location.href = constructedUri;
    }
  };

  return (
    <>
      {isRedirectEnabled ? (
        <Loader isOpen={true} />
      ) : (
        <div className="flex flex-col grow gap-y-8 mt-16">
          <div className="flex justify-center">
            <div
              style={{ backgroundImage: `url("./icons/celebrate.svg")` }}
              className={`flex-none h-32 w-full bg-contain bg-no-repeat bg-center`}
              aria-label={step}
            ></div>
          </div>
          <div className="flex flex-col justify-center gap-y-4 text-center text-black">
            <h3 className="font-bold text-xl">
              {get(session, "config.success.header") ?? "All set!"}
            </h3>
            <p className="text-xl">
              {get(session, "config.success.subheader") ?? SUCCESS_SUBHEADER}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Success;
