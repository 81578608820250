import { callAxleService } from "./axle";
import { DMV_LOCAL } from "./constants";

const dmvBaseUrl = DMV_LOCAL
  ? "http://localhost:4000/dev"
  : `https://${
      process.env.REACT_APP_STAGE === "prod"
        ? "api"
        : process.env.REACT_APP_STAGE
    }.axle.insure`;

type IgnitionUser = {
  id: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
};

export const startIgnition = async (
  clientId: string,
  clientSecret: string,
  user: IgnitionUser,
  redirectUri?: string,
  webhookUri?: string
) => {
  return await callAxleService(
    `https://${process.env.REACT_APP_STAGE}.axle.insure/ignition`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "x-client-id": clientId, // Ignition Super Test
        "x-client-secret": clientSecret, // Client secret for this client
      },
      data: {
        user,
        redirectUri,
        webhookUri,
      },
    },
    "axios"
  );
};

export const exchangeToken = async (
  authCode: string,
  clientId: string,
  clientSecret: string
) => {
  return await callAxleService(
    `${dmvBaseUrl}/token/exchange`,
    {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-client-id": clientId, // Ignition Super Test
        "x-client-secret": clientSecret,
      },
      data: {
        authCode,
      },
    },
    "axios"
  );
};

export const getAccount = async (
  clientId: string,
  accessToken: string,
  account: string
) => {
  return await callAxleService(`${dmvBaseUrl}/accounts/${account}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "x-client-id": clientId,
      "x-access-token": accessToken,
    },
  });
};

/**
 * It makes a GET request to the DMV API to get a client's information
 * @param clientId - The id of the client you want to get.
 * @returns The client object
 */
export const getClient = async (clientId: string) => {
  return await callAxleService(`${dmvBaseUrl}/clients/${clientId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/**
 * It makes a GET request to the Ignition API to get the configuration of a client
 * @param clientId - The client ID of the client you want to get the ignition config for.
 * @returns the data from the response body.
 */
export const getClientWithIgnitionConfig = async (clientId: string) => {
  return await callAxleService(
    `${dmvBaseUrl}/clients/${clientId}/ignition-config`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
