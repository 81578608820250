export const S3_IMAGES_BASE_URL = "https://axle-labs-assets.s3.amazonaws.com";
export const CREDIT_CARD_IMAGE_BASE_S3 = "creditCardLogos";

export const DEFAULT_CREDIT_CARD_IMAGE = `${S3_IMAGES_BASE_URL}/${CREDIT_CARD_IMAGE_BASE_S3}`;
export const DEFAULT_CREDIT_CARD_IMAGE_GENERIC = `${S3_IMAGES_BASE_URL}/${CREDIT_CARD_IMAGE_BASE_S3}/generic.svg`;

// Carriers that require zipcode input during login
export const CARRIERS_WITH_ZIPCODE_LOGIN = ["aaa", "connect-by-amfam"];

export const SENTRY_TAG_IGNITION_TOKEN = "ignitionToken";
export const SENTRY_TAG_CLIENT_ID = "clientId";

// Error and success message constants
export const CARRIER_ERROR_SUBHEADER =
  "We're unable to connect to your carrier right now.";
export const CONNECTION_ERROR_SUBHEADER =
  "The Axle service can not be reached. Please check your network connection and then try again.";
export const AXLE_ERROR_SUBHEADER =
  "The Axle service can not be reached. Please try again later.";
export const SUCCESS_SUBHEADER =
  "Your information has been shared successfully.";
export const INVALID_INPUT_HEADER =
  "One or more inputs are invalid. Please review and try again.";

export const CREDIT_CARD_OPTION = "Use my credit card protection instead";

export const NAVIGATION_NOTE =
  "Please do not close or navigate away from this page while we process your request.";
export const CURRENTLY_PROCESSING_DOCUMENT =
  "We are currently processing your document.";

export const windowUrl = "http://localhost:4000";
