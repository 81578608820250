import { ButtonPrimary } from "../components/Button";
import { stubAccount, stubPolicy } from "../lib/axle";

// External dependencies
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";

const BasicPolicyInfo = ({
  nextStep,
  setAccountInfo,
  setPolicyInfo,
  loginInformation,
  session,
  posthog,
  step,
}) => {
  const ignitionToken = session.id;

  const [vehicleYear, setVehicleYear] = useState(1900);
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [error, setError] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!vehicleYear) {
      setError("Please add your vehicle year!");
      return;
    }

    if (!vehicleMake) {
      setError("Please add your vehicle make!");
      return;
    }

    if (!vehicleModel) {
      setError("Please add your vehicle model!");
      return;
    }

    const processDetail = "basic";

    //TODO: remove once we validate which pathway doesn't set ignitionToken (refer to AXL-4235)
    if (isEmpty(ignitionToken)) {
      console.log(`processDetail: ${processDetail}`);
      console.error("No ignitionToken provided"); //Use this .error to search in Sentry
    }

    // Save account stub
    const [account, accountError] = await stubAccount(
      ignitionToken,
      {
        carrier: get(loginInformation, "carrier.id"),
      },
      processDetail
    );

    if (accountError) {
      switch (get(accountError, "code")) {
        case 500:
          console.log(JSON.stringify(accountError));
          nextStep("failed");
          setError(accountError.message);
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    // Save policy stub

    const [stubPolicyResponse, stubPolicyError] = await stubPolicy(
      ignitionToken,
      {
        account: account.id,
        carrier: get(loginInformation, "carrier.id"),
        vehicleModel,
        vehicleYear,
        vehicleMake,
      },
      processDetail
    );

    if (stubPolicyError) {
      switch (get(stubPolicyError, "code")) {
        case 500:
          console.log(JSON.stringify(stubPolicyError));
          nextStep("failed");
          setError(stubPolicyError.message);
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    // Set to state
    setAccountInfo({ id: account.id });
    setPolicyInfo({ id: stubPolicyResponse.id });

    // If require backup docs, go to manual_policy_docs, else go to success
    get(session, "config.basic.documents")
      ? nextStep("manual-policy-document")
      : nextStep("remember-user");
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog, step]);

  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
          aria-label="Axle"
        ></div>
        <div
          style={{
            backgroundImage: `url("${get(loginInformation, "carrier.image") ??
              `https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg`
              }")`,
            // backgroundColor: loginInformation.carrier.color,
          }}
          className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
          aria-label={get(loginInformation, "carrier.name")}
        ></div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">
          Enter your vehicle information
        </h3>
        <p className="text-base text-black">
          Axle will only share the{" "}
          <b>{get(loginInformation, "carrier.name")}</b> policy associated with
          this vehicle.
        </p>
      </div>
      <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-4">
          {error && (
            <div
              className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
              role="status"
            >
              {" "}
              {error}{" "}
            </div>
          )}
          <input
            placeholder="Vehicle year"
            value={vehicleYear}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="number"
            onChange={(e) => setVehicleYear(e.target.value)}
          />
          <input
            placeholder="Vehicle make"
            value={vehicleMake}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setVehicleMake(e.target.value)}
          />
          <input
            placeholder="Vehicle model"
            value={vehicleModel}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setVehicleModel(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-y-6 mt-auto">
          <hr className="-mx-8 border-gray-100" />
          <ButtonPrimary text={"Continue"} width={"w-full"} type={"submit"} />
        </div>
      </form>
    </>
  );
};

export default BasicPolicyInfo;
