import { useEffect } from "react";
import { ButtonSecondary } from "../components/Button";

const SelectVerificationMethod = ({
  step,
  nextStep,
  loginInformation,
  posthog,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);
  return (
    <>
      <div className="grow flex flex-col gap-8">
        <div className="flex">
          <div className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"></div>
          <div
            style={{
              backgroundImage: `url("${loginInformation.carrier.image}")`,
            }}
            className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
            aria-label={loginInformation.carrier.name}
          ></div>
        </div>
        <div className="flex flex-col gap-y-2">
          <h3 className="text-xl text-black font-bold">
            Share your insurance information
          </h3>
          <p className="text-lg text-black">
            Please select how you’d like to share your insurance information.
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <ButtonSecondary
            onClick={() => nextStep("login")}
            text={`Log in with ${loginInformation.carrier.name}`}
            outlined={true}
          />
          <ButtonSecondary
            onClick={() => nextStep("policy-lookup")}
            text={"Enter your policy number and car VIN number"}
            outlined={true}
          />
        </div>
      </div>
    </>
  );
};

export default SelectVerificationMethod;
