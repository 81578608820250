import { get, size } from "lodash";
import React, { JSX } from "react";
/**
 * Returns a bullet point list of descriptions about each accepted doc type. Created based off what doc types are accepted by the client, as specified on the config
 * @param session 
 * @returns 
 */
export const getAcceptedDocumentDescriptions = (session: Record<string, any>) => {
    const acceptedDocs =
      get(session, "config.documentation.acceptedDocs");
  
    // Define the bullet point elements with proper TypeScript JSX typing
    const decPageBullet: JSX.Element = (
      <li className="text-sm">
        <strong>A declarations page</strong> contains a list of
        coverages and other detailed policy info
      </li>
    );
    
    const newLine: JSX.Element = (
      <li className="text-sm" style={{ listStyleType: "none" }}>
        &nbsp;
      </li>
    );
    
    const idCardBullet: JSX.Element = (
      <li className="text-sm">
        <strong>An ID card</strong> is a small card, like a driver's
        license, showing high level policy info
      </li>
    );
  
    // If client has not specified accepted docs, return default display text with both bullet points
    if (!acceptedDocs || !size(acceptedDocs)) {
      return (
        <ul className="list-disc pl-5 text-gray-500">
          {decPageBullet}
          {newLine}
          {idCardBullet}
        </ul>
      );
    }
  
    // Check which document types are accepted
    const hasDecPage = acceptedDocs.some((doc: any) => doc.type === "declaration-page");
    const hasIdCard = acceptedDocs.some((doc: any) => doc.type === "id-card");
  
    // Construct bullet points based on accepted document types
    return (
      <ul className="list-disc pl-5 text-gray-500">
        {hasDecPage && decPageBullet}
        {hasDecPage && hasIdCard && newLine}
        {hasIdCard && idCardBullet}
      </ul>
    );
  };
  
  /**
   * Forms the header on the manual document upload page based on the accepted document types
   * @param session 
   * @returns 
   */
  export const getUploadHeader = (session: Record<string, any>): string => {
    let baseHeader = "Upload your"
    const acceptedDocs = get(session, "config.documentation.acceptedDocs");
    const defaultHeader = `${baseHeader} declarations page or ID card`;
  
    // If client has not specified accepted docs, return default display text
    if (!acceptedDocs || !size(acceptedDocs)) {
      return defaultHeader;
    }
  
    const hasDecPage = acceptedDocs.some((doc: any) => doc.type === "declaration-page");
    const hasIdCard = acceptedDocs.some((doc: any) => doc.type === "id-card");
  
    // If client only supports dec page, return dec page header
    if (hasDecPage && !hasIdCard) {
      return `${baseHeader} declarations page`;
    }
  
    // If client only supports ID card, return ID card header
    if (!hasDecPage && hasIdCard) {
      return `${baseHeader} ID card`;
    }
    // If client supports both, return generic header
    if (hasDecPage && hasIdCard) {
      return defaultHeader;
    }
  
    return defaultHeader;
  }