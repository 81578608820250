import { ButtonPrimary } from "../components/Button";
import { INVALID_INPUT_HEADER } from "../constants";
import { stubPolicy } from "../lib/axle";

// External dependencies
import _, { get, trim } from "lodash";
import { useEffect, useState } from "react";

const validatePolicyInput = (policyNumber, expirationDate, setError) => {
  policyNumber = trim(policyNumber);
  expirationDate = trim(expirationDate);

  if (!policyNumber) {
    setError("Please add a policy number!");
    return false;
  }

  if (!expirationDate) {
    setError("Please add an expiration date!");
    return false;
  }

  return true;
};

const ManualPolicyInfo = ({
  step,
  nextStep,
  accountInfo,
  policyInfo,
  setPolicyInfo,
  loginInformation,
  session,
  posthog,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  const [policyNumber, setPolicyNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [inputType, setInputType] = useState("text");
  const [error, setError] = useState(null);

  const ignitionToken = _.chain(session).get("id").value(); //session.id;

  const hasCarrier = _.chain(loginInformation).get("carrier.id").value()
    ? true
    : false;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!validatePolicyInput(policyNumber, expirationDate, setError)) {
      return;
    }

    const constructedPolicy = {
      account: get(accountInfo, "id"),
      carrier: get(accountInfo, "carrier"),
      firstName: get(accountInfo, "firstName"),
      lastName: get(accountInfo, "lastName"),
      policyNumber,
      expirationDate: expirationDate && new Date(expirationDate).toISOString(),
    };

    const processDetail = "policy-form";
    let policyId = get(policyInfo, "id", "");

    // Save policy information
    const [stubPolicyResponse, stubPolicyResponseError] = await stubPolicy(
      ignitionToken,
      constructedPolicy,
      processDetail
    );

    if (stubPolicyResponseError) {
      switch (get(stubPolicyResponseError, "code")) {
        case 400:
          if (
            stubPolicyResponseError.message ===
            "Input validation failed due to one or more missing or invalid parameters. Review the request and try again."
          ) {
            setError(INVALID_INPUT_HEADER);
            return;
          }
        case 500:
          console.log(JSON.stringify(stubPolicyResponseError));
          console.log(`Failed to stub policy`);
          nextStep("failed");
          setError(stubPolicyResponseError.message);
          return;

        default:
          nextStep("connection-error");
          return;
      }
    }

    policyId = get(stubPolicyResponse, "id");

    setPolicyInfo({ id: policyId });

    // Go to manual document upload
    nextStep("manual-policy-document");
  };

  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
          aria-label="Axle"
        ></div>
        <div
          style={{
            backgroundImage: `url("${
              hasCarrier
                ? _.chain(loginInformation).get("carrier.image").value() // get(loginInformation, "carrier.image")
                : `https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg`
            }")`,
            // backgroundColor: loginInformation.carrier.color,
          }}
          className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
          aria-label={
            hasCarrier
              ? get(loginInformation, "carrier.name")
              : "Unknown carrier"
          }
        ></div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">
          Enter your policy summary
        </h3>
        <p className="text-base text-black">
          {hasCarrier ? (
            <>
              Axle will provide this information if we are unable to connect
              with{" "}
              <b>{_.chain(loginInformation).get("carrier.name").value()}</b>.
            </>
          ) : (
            <>Axle will use this information to verify your policy details.</>
          )}
        </p>
      </div>
      <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-4">
          {error && (
            <div
              className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
              role="status"
            >
              {" "}
              {error}{" "}
            </div>
          )}
          <input
            placeholder="Policy number"
            value={policyNumber}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setPolicyNumber(e.target.value)}
          />
          <input
            placeholder="Expiration date"
            value={expirationDate}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type={inputType}
            onFocus={(e) => {
              setInputType("date");
            }}
            onChange={(e) => setExpirationDate(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-y-6 mt-auto">
          <hr className="-mx-8 border-gray-100" />
          <ButtonPrimary
            text={"Continue"}
            //onClick={() => nextStep(step)}
            width={"w-full"}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
};

export default ManualPolicyInfo;
