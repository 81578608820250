import { get } from "lodash";

/**
 * Handles errors that occur during alternative link processing
 */
export const handleProcessAlternativeLinkError = ({
  error,
  linkAttempts,
  setLinkAttempts,
  setError,
  nextStep,
  setPromiseInProgress,
  setLinkError,
  carrier,
}) => {
  console.error(
    "Processing alternative link failed with error: ",
    JSON.stringify(error)
  );

  switch (error.code) {
    case 400: {
      switch (error.message) {
        case "User input is invalid. Please try again.": {
          // If user has already tried 2 times
          if (get(linkAttempts, carrier, 0) + 1 > 2) {
            nextStep("max-link-attempts");
            return;
          }

          // Otherwise, increment the link attempt counter
          setLinkAttempts({
            ...linkAttempts,
            [carrier]: get(linkAttempts, carrier, 0) + 1,
          });

          // Update error message
          error.message = `Policy number or last 5 of VIN is incorrect. You have ${
            3 - (get(linkAttempts, carrier, 0) + 1)
          } ${
            3 - (get(linkAttempts, carrier, 0) + 1) === 1
              ? "attempt"
              : "attempts"
          } remaining.`;
          setPromiseInProgress(false);
          setError(error.message);
          return;
        }
        case "Sandbox only supports test credentials. Please try again.":
          setPromiseInProgress(false);
          setError(error.message);
          return;
        default:
          console.log("Unknown 400 error from alternative link: ", error);
          setLinkError("look up your policy");
          nextStep("carrier-error");
          return;
      }
    }

    case 500: {
      switch (error.message) {
        case "Oops! Something went wrong.":
        case "Oops something went wrong. Please try again later.":
          console.log("500 error from alternative link: ", error);
          setLinkError("look up your policy");
          nextStep("carrier-error");
          return;
        default:
          console.error("Unknown 500 error from alternative link: ", error);
          setLinkError("look up your policy");
          nextStep("carrier-error");
          return;
      }
    }

    default:
      console.error("Unknown error from alternative link: ", error);
      setLinkError("look up your policy");
      nextStep("carrier-error");
      return;
  }
};
