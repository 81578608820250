import React from 'react';
import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import { RadioGroup } from "@headlessui/react";
import Account from "../components/Account";
import Loader from "../components/Loader";

import { getValue, removeValue } from "../lib/cookies";
import { verifyDeviceToken, updateIgnition } from "../lib/axle";

import { useEffect, useState } from "react";
import { genericClientLogoUrl, ReturningUserEnum } from "../lib/constants";
import _, { get } from "lodash";
import { getCarrierLogo } from '../lib/carrier';

interface WelcomeBackProps {
  step: string;
  nextStep: (nextStep: string, noHistory?: boolean) => void;
  posthog: any;
  setLoginInformation: (info: any) => void;
  client: any;
  session: any;
  setSession: (session: any) => void;
}

interface RuxTokenData {
  accounts: Array<{
    id: string;
    carrier: string;
    firstName?: string;
    lastName?: string;
    email?: string;
  }>;
  carriers: Array<{
    id: string;
    name: string;
    hasImage: boolean;
    image?: string;
  }>;
  isValid: boolean;
}

const WelcomeBack = ({
  step,
  nextStep,
  posthog,
  setLoginInformation,
  client,
  session,
  setSession,
}: WelcomeBackProps) => {
  const [loading, setLoading] = useState(true);
  // Stores the cookie value
  const [cookie, setCookie] = useState<string | null>(null);
  // Stores the cookie domain for proper deletion
  const [cookieDomain, setCookieDomain] = useState<string | null>(null);

  // Stores the cookie data returned from the backend after decryption
  const [ruxTokenData, setRuxTokenData] = useState<RuxTokenData | null>(null);
  const [selectedAccountIndex, setSelectedAccountIndex] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<RuxTokenData['accounts'][number] | null>(null);

  useEffect(() => {
    const checkCookie = async () => {
      // Track page view
      posthog.capture("$pageview", { step });

      // Check for axle token - try cookie first, then local storage
      const tokenValue = getValue("rux") || null;

      // Update state for later use
      setCookie(tokenValue);

      // If no token found, go to carriers page
      if (!tokenValue) {
        nextStep("carriers", true);
        return;
      }

      try {
        // Validate the token with our backend API
        const result = await verifyDeviceToken(tokenValue);

        // Check if result is undefined or not an array
        if (!result || !Array.isArray(result)) {
          nextStep("carriers", true);
          return;
        }

        const [data, error] = result;

        if (error || !data || !data.isValid) {
          console.error(
            "Token validation failed:",
            error?.message || "Invalid token"
          );
          nextStep("carriers", true);
          return;
        }

        // Extract the cookie domain from the options stored in the backend
        // Either from data directly or from window.location.hostname as fallback
        const currentHostname = window.location.hostname; // e.g. ignition.dev.axle.insure
        const hostnameParts = currentHostname.split('.');

        // Determine the cookie domain based on the current hostname pattern
        let domain = null;
        if (hostnameParts.length >= 4 && hostnameParts[0] === 'ignition') {
          // For ignition.dev.axle.insure -> .dev.axle.insure
          domain = '.' + hostnameParts.slice(1).join('.');
        } else if (hostnameParts.length >= 3) {
          // For ignition.axle.insure -> .ignition.axle.insure
          domain = '.' + currentHostname;
        }

        setCookieDomain(domain);
        setRuxTokenData(data);

        // Set default selected account to the first one
        if (data && data.accounts && data.accounts.length > 0) {
          setSelectedAccount(data.accounts[0]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error validating token:", error);
        // On error, proceed to carriers page
        nextStep("carriers", true);
      }
    };

    checkCookie();
  }, [posthog, step, nextStep]);

  const handleAccountSelect = (index: number) => {
    setSelectedAccountIndex(index);
  };

  const handleUseExistingAccount = async () => {
    if (!ruxTokenData) {
      return;
    }

    // Get the selected account based on the index
    const account = ruxTokenData.accounts[selectedAccountIndex];

    // Set login information with the carrier from the selected account
    if (account && account.carrier) {
      let carrier = ruxTokenData.carriers.find(
        (c) => c.id === account.carrier
      );

      if (!carrier) {
        console.error("Carrier not found for account:", account);
        return;
      }

      // Update the ignition session with returningUser set to "re-link"
      try {
        const returningUser: ReturningUserEnum = "re-link";
        const updateResult = await updateIgnition(session.id, {
          returningUser,
        });
        setSession({
          ...session,
          returningUser,
        });

        if (updateResult && updateResult[1]) {
          console.error("Error updating ignition session:", updateResult[1]);
        }
      } catch (error) {
        console.error("Exception updating ignition session:", error);
      }

      carrier.image = getCarrierLogo(carrier);

      setLoginInformation({
        account: account.id,
        cookie,
        carrier
      });

      // Track user choice
      posthog.capture("welcome_back_use_existing", {
        carrier: account.carrier,
      });

      // Go to login page
      nextStep("login");
    }
  };

  const handleChooseDifferent = async () => {
    // Update the ignition session with returningUser set to "returning-user-new-link"
    try {
      const returningUser: ReturningUserEnum = "new-account";
      const updateResult = await updateIgnition(session.id, {
        returningUser,
      });

      setSession({
        ...session,
        returningUser,
      })

      setLoginInformation({
        account: null,
        cookie,
        carrier: null
      });

      if (updateResult && updateResult[1]) {
        console.error("Error updating ignition session:", updateResult[1]);
      }
    } catch (error) {
      console.error("Exception updating ignition session:", error);
    }

    // Track user choice
    posthog.capture("welcome_back_choose_different");

    // Go to carriers page
    nextStep("carriers");
  };

  // Handler for the "These are not my accounts" button
  const handleNotMyAccounts = async () => {
    // Track user choice with distinct event name
    posthog.capture("welcome_back_dont_recognize");

    // Update the ignition session with returningUser set to null
    try {
      const returningUser: ReturningUserEnum = "new-user";
      const updateResult = await updateIgnition(session.id, {
        returningUser,
      });
      setSession({
        ...session,
        returningUser,
      });

      if (updateResult && updateResult[1]) {
        console.error("Error updating ignition session:", updateResult[1]);
      }
    } catch (error) {
      console.error("Exception updating ignition session:", error);
    }

    setLoginInformation({
      account: null,
      cookie: null,
      carrier: null
    });

    // Remove token from both cookie and local storage using the detected domain
    removeValue("rux", cookieDomain ?? undefined);

    // Redirect to carriers step
    nextStep("carriers", true);
  };

  // If loading or no rux token data, return empty (will automatically redirect)
  if (loading || !ruxTokenData) {
    return <Loader isOpen={true} />;
  }

  const clientLogoUrl = client.logoUrl ?? genericClientLogoUrl;
  const logoClass =
    "inline-block rounded-full h-12 w-12 bg-white bg-no-repeat bg-cover bg-center border border-solid border-white box-border transform -translate-x-1.5";

  // Handle both the new multiple accounts format and the old single account format
  const { accounts, carriers } = ruxTokenData;


  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center"
          aria-label="Axle"
        ></div>
        <div
          className={logoClass}
          style={{ backgroundImage: `url('${clientLogoUrl}')` }}
          aria-label={client.displayName}
        ></div>
      </div>

      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">Welcome back!</h3>
        <p className="text-lg text-black">
          {accounts.length > 1
            ? "Connect instantly with one of your saved accounts."
            : "Connect instantly with your saved account."}
        </p>
      </div>

      {/* Added flex-grow to ensure the list takes available space and properly scrolls */}
      <div className="flex-grow overflow-y-auto -mx-2 px-2 -mb-8 pb-8">
        {accounts.length > 0 ? (
          <RadioGroup
            value={selectedAccount}
            onChange={setSelectedAccount}
            className="flex flex-col gap-y-4"
          >
            {accounts.map((account, index) => {
              // Find the carrier that matches this account
              const carrier =
                carriers.find((c) => c.id === account.carrier) ||
                carriers[index] ||
                {};

              return (
                <Account
                  key={carrier.id || index}
                  account={account}
                  carrier={carrier}
                  onClick={() => handleAccountSelect(index)}
                />
              );
            })}
          </RadioGroup>
        ) : (
          <p className="text-black">No saved accounts found.</p>
        )}
      </div>

      <div className="flex flex-col gap-y-4 mt-auto">
        <hr className="-mx-8 border-gray-100" />
        {accounts.length > 0 && selectedAccount && (
          <>
            <ButtonPrimary
              name="continue"
              text={`Continue with ${get(
                carriers[selectedAccountIndex],
                "name",
                "selected carrier"
              )}`}
              type="submit"
              onClick={handleUseExistingAccount}
              width="w-full"
            />
            <ButtonSecondary
              name="choose-different"
              text="Choose a different carrier"
              onClick={handleChooseDifferent}
              type="button"
              outlined={true}
            />
            <ButtonSecondary
              name="not-my-accounts"
              text="I don't recognize these accounts"
              onClick={handleNotMyAccounts}
              type="button"
            />
          </>
        )}
      </div>
    </>
  );
};

export default WelcomeBack;
