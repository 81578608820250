import { ButtonPrimary } from "../components/Button";
import Icon from "../components/Icon";

import { genericClientLogoUrl } from "../lib/constants";
import { COOKIE_NAMES, getCookie } from "../lib/cookies";

// External dependencies
import { useEffect, useState } from "react";

const Consent = ({ step, nextStep, client, posthog }) => {
  const [axleCookie, setAxleCookie] = useState(null);

  // Effect for PostHog tracking
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  // Separate effect for cookie retrieval that doesn't depend on posthog
  useEffect(() => {
    // Get the axle cookie when the page loads
    const cookie = getCookie(COOKIE_NAMES["rux"]);
    if (cookie) {
      setAxleCookie(cookie);
    }
  }, []); // Empty dependency array means this runs once on mount


  const logoClass =
    "inline-block rounded-full h-16 w-16 bg-white bg-no-repeat bg-cover bg-center border border-solid border-white box-border transform -translate-x-2	";

  const clientLogoUrl = client.logoUrl ?? genericClientLogoUrl;

  return (
    <>
      <div className="flex flex-col gap-8">
        <div className="flex justify-center">
          <div
            className="inline-block rounded-full h-16 w-16 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center"
            aria-label="Axle"
          ></div>
          <div
            className={logoClass}
            style={{ backgroundImage: `url('${clientLogoUrl}')` }}
            aria-label={client.displayName}
          ></div>
        </div>
        <h3 className="text-xl text-center text-black">
          <b>{client.displayName}</b> uses Axle to securely share your insurance
          information
        </h3>
      </div>
      <div className="grow overflow-auto flex flex-col gap-8">
        <ul className="flex flex-col gap-y-6 " aria-labelledby="Consent steps">
          <li className="flex gap-x-3" tabIndex={`0`}>
            <Icon name="checkmark" ariaLabel="Secure" />
            <div>
              <h4 className="text-base font-bold text-black">Secure</h4>
              <p className="text-base text-black">
                Axle employs bank-level encryption to secure your data
                end-to-end
              </p>
            </div>
          </li>
          <li className="flex gap-x-3" tabIndex={`0`}>
            <Icon name="checkmark" ariaLabel="Transparent" />
            <div>
              <h4 className="text-base font-bold text-black">Transparent</h4>
              <p className="text-base text-black">
                You’ll see exactly what information will be shared with the
                application
              </p>
            </div>
          </li>
        </ul>
      </div>
      {/* <div className="flex flex-col sm:gap-y-6 -mt-8 sm:mt-auto border-gray-10"> */}
      <div className="flex flex-col gap-y-6">
        <hr className="-mx-8 -mt-8 border-gray-100" />
        <p className="text-center text-gray-500 text-xs border-gray-10 bg-white">
          By clicking “Continue”, you agree to Axle's{" "}
          <a
            target="_blank"
            href="https://www.axle.insure/legal/end-user-terms"
            className="hover:text-black focus:text-black font-medium underline"
          >
            End User Terms
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            href="https://www.axle.insure/legal/privacy-policy"
            className="hover:text-black focus:text-black font-medium underline"
          >
            Privacy Policy
          </a>
        </p>
        {/* </div> */}
        <ButtonPrimary
          text={"Continue"}
          onClick={() => nextStep("permissions")}
          width={"w-full"}
        />
      </div>
    </>
  );
};

export default Consent;
