import _, { get, isEmpty, toLower } from "lodash";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { DocumentUpload } from "../components/DocumentUpload";
import {
  generateSignedUrl,
  processDocuments,
  stubAccount,
  stubPolicy,
  updatePolicy,
  uploadDocument,
} from "../lib/axle";
import { compressImage } from "../lib/utility";

const MAP_FILE_EXT_TO_MIME = {
  jpeg: "image/jpeg",
  jpg: "image/jpeg",
  png: "image/png",
};

const EnhancedManualPolicyDocument = ({
  step,
  nextStep,
  accountInfo,
  setAccountInfo,
  policyInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  manualRetryCount,
  setManualRetryCount,
  session,
  posthog,
  setManualError,
  setShowNav,
}) => {
  const ignitionToken = session.id;

  const loadingSteps = [
    "Uploading your document",
    "Extracting data from your document",
    "Processing extracted data",
  ];

  const uploadWrapper = async (currentFileData, currentFilePath) => {
    // Use the parameters that are passed in from DocumentUpload, or use current state
    let updatedFileData = currentFileData;
    const updatedFilePath = currentFilePath;

    // Check for file data rather than the hasFile state
    if (!updatedFileData) {
      alert("Please upload a document!");
      return;
    }

    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    const [signedUrlResponse, signedUrlResponseError] = await generateSignedUrl(
      ignitionToken,
      policyInfo.id,
      updatedFilePath
    );

    if (signedUrlResponseError) {
      switch (get(signedUrlResponseError, "code")) {
        case 500:
          console.log(
            "Failed with error... retry count is: ",
            manualRetryCount
          );
          setManualRetryCount(manualRetryCount + 1);
          setManualError("process");
          nextStep("manual-document-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    if (isEmpty(get(signedUrlResponse, "signedUrl"))) {
      console.error(
        "Signed URL is empty. Failed with error... retry count is: ",
        manualRetryCount
      );
      setManualRetryCount(manualRetryCount + 1);
      setManualError("process");
      nextStep("manual-document-error");
      return;
    }

    // If user uploads an image, we need to make sure the image is within our size and pixel constraints
    if (!toLower(updatedFilePath).includes(".pdf")) {
      const fileExtension = _.chain(updatedFilePath)
        .split(".")
        .last()
        .toLower()
        .value();
      const blob = new Blob([updatedFileData], {
        type: get(MAP_FILE_EXT_TO_MIME, fileExtension),
      });

      // Create a File object from the Blob
      let file = new File([blob], updatedFilePath, {
        type: get(MAP_FILE_EXT_TO_MIME, fileExtension),
      });

      // Compress image to at most 40 megapixels and 5 MB
      const compressedImage = await compressImage(
        file,
        get(MAP_FILE_EXT_TO_MIME, fileExtension)
      );
      // Need to manually assign the value for use in following code
      updatedFileData = compressedImage;
    }

    const [documentUploadResponse, documentUploadResponseError] =
      await uploadDocument(signedUrlResponse.signedUrl, updatedFileData);

    if (documentUploadResponseError) {
      switch (get(documentUploadResponseError, "code")) {
        case 500:
          console.log(
            "Failed with error... retry count is: ",
            manualRetryCount
          );

          const errorMessage = get(
            documentUploadResponseError,
            "message",
            "process"
          );
          setManualError(errorMessage);

          setManualRetryCount(manualRetryCount + 1);

          nextStep("manual-document-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    const documentKey = signedUrlResponse.objectKey;
    let documentsObject = [
      {
        source: "user",
        name: updatedFilePath,
        key: documentKey,
        createdAt: new Date().toISOString(),
        type: null,
        effectiveDate: null,
        issuedDate: null,
      },
    ];

    let policyId = policyInfo.id;
    let accountId = accountInfo.id;

    setLoginInformation({
      ...loginInformation,
      result: "manual",
      resultDetail: "enhanced-manual", // Set to enhanced-manual as a placeholder until we get the more detailed detail name back from process documents
    });

    // Process documents via autopilot, which will handle the classification and
    // validation of the document. Call processDocuments if retry count is < 2
    if (manualRetryCount < 2) {
      // Returns account ID, policy ID, upserts documents in processDocuments
      const [processDocumentsResponse, processDocumentsResponseError] =
        await processDocuments(ignitionToken, documentKey, documentsObject);

      if (processDocumentsResponseError) {
        console.log(
          "Processing documents via autopilot failed with retry: ",
          manualRetryCount
        );

        setManualRetryCount(manualRetryCount + 1);
        switch (get(processDocumentsResponseError, "code")) {
          case 400:
            nextStep("unsupported-document");
            return;
          case 500:
            if (
              processDocumentsResponseError.message === "Text extraction failed"
            ) {
              setManualError("extract text from");
            } else if (
              processDocumentsResponseError.message ===
              "Failed to split and classify the document"
            ) {
              setManualError("accurately classify");
            } else if (
              processDocumentsResponseError.message ===
              "Failed to analyze the document"
            ) {
              setManualError("analyze text from");
            }
            nextStep("manual-document-error");
            return;
          default:
            nextStep("connection-error");
            return;
        }
      } else {
        accountId = get(processDocumentsResponse, "accountId", "");
        policyId = get(processDocumentsResponse, "policyId", "");

        const resultDetail = get(
          processDocumentsResponse,
          "metadata.processDetail"
        );

        setLoginInformation({
          ...loginInformation,
          result: "manual", // Adding result again here because of a race condition, where login information that was set above is not set in time for this call
          resultDetail,
        });
      }

      // If we have exceeded the manual retry count, update the policy with the document and go to success
    } else {
      const processDetail = "enhanced-manual-push-through";
      // Stub account and policy
      console.log(
        "Stubbing account and policy, then updating the policy with the document on the 2nd retry..."
      );

      //TODO: remove once we validate which pathway doesn't set ignitionToken (refer to AXL-4235)
      if (isEmpty(ignitionToken)) {
        console.log(`processDetail: ${processDetail}`);
        console.error("No ignitionToken provided"); //Use this .error to search in Sentry
      }

      const [accountStubbed, accountStubbedError] = await stubAccount(
        ignitionToken,
        {
          carrier: get(loginInformation, "carrier.id", "Other"),
        },
        processDetail
      );

      if (accountStubbedError) {
        console.log("Failed with error... retry count is: ", manualRetryCount);
        setManualRetryCount(manualRetryCount + 1);
        switch (get(accountStubbedError, "code")) {
          case 400:
            nextStep("unsupported-document");
            return;
          case 500:
            if (accountStubbedError.message === "Text extraction failed") {
              setManualError("extract text from");
            } else if (
              accountStubbedError.message ===
              "Failed to split and classify the document"
            ) {
              setManualError("accurately classify");
            } else if (
              accountStubbedError.message === "Failed to analyze the document"
            ) {
              setManualError("analyze text from");
            }
            nextStep("manual-document-error");
            return;
          default:
            setManualError("process");
            nextStep("manual-document-error");
            return;
        }
      }

      const [policyStubbed, policyStubbedError] = await stubPolicy(
        ignitionToken,
        {
          account: accountStubbed.id,
          carrier: get(loginInformation, "carrier.id", "Other"),
        },
        processDetail
      );

      if (policyStubbedError) {
        console.log("Failed with error... retry count is: ", manualRetryCount);
        setManualRetryCount(manualRetryCount + 1);
        switch (get(policyStubbedError, "code")) {
          case 400:
            nextStep("unsupported-document");
            return;
          case 500:
            if (policyStubbedError.message === "Text extraction failed") {
              setManualError("extract text from");
            } else if (
              policyStubbedError.message ===
              "Failed to split and classify the document"
            ) {
              setManualError("accurately classify");
            } else if (
              policyStubbedError.message === "Failed to analyze the document"
            ) {
              setManualError("analyze text from");
            }
            nextStep("manual-document-error");
            return;
          default:
            setManualError("process");
            nextStep("manual-document-error");
            return;
        }
      }

      const [updatePolicyResponse, updatePolicyResponseError] =
        await updatePolicy(ignitionToken, policyStubbed.id, accountStubbed.id, {
          documents: documentsObject,
        });

      if (updatePolicyResponseError) {
        console.log("Failed with error... retry count is: ", manualRetryCount);
        setManualRetryCount(manualRetryCount + 1);
        switch (get(updatePolicyResponseError, "code")) {
          case 500:
            console.log(JSON.stringify(updatePolicyResponseError));
            nextStep("axle-error");
            return;
          default:
            nextStep("connection-error");
            return;
        }
      }

      accountId = accountStubbed.id;
      policyId = policyStubbed.id;

      setLoginInformation({
        ...loginInformation,
        resultDetail: processDetail,
      });
    }

    // Setting account and policy info if we have already surpassed retry count
    setAccountInfo({ id: accountId });
    setPolicyInfo({ id: policyId });

    nextStep("remember-user");
    return;
  };

  const uploadFile = async (currentFileData, currentFilePath) => {
    await trackPromise(
      uploadWrapper(currentFileData, currentFilePath),
      "carrier-loader"
    );

    // Show nav buttons after CarrierLoader is unrendered
    setShowNav(true);
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  return (
    <>
      <DocumentUpload
        session={session}
        loginInformation={loginInformation}
        uploadFile={uploadFile}
        loadingSteps={loadingSteps}
      />
    </>
  );
};

export default EnhancedManualPolicyDocument;
