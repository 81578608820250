import { useState, useEffect } from "react";
import _, { get, size } from "lodash";
import { getCarrierLogo } from "../lib/carrier";

export const Row = ({ backgroundImageUrl, rowHeader, rowSubHeader }) => {
  return (
    <div className="flex gap-x-4 ">
      <div
        style={{
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
        className="flex-none inline-block rounded-full h-12 w-12 bg-blue bg-cover bg-center"
        aria-label={rowHeader}
      ></div>
      <div>
        <h4 className="text-base font-medium text-black">{rowHeader}</h4>
        <p className="text-base text-black">{rowSubHeader}</p>
      </div>
    </div>
  );
};

const Carrier = ({ carrier, config, setCarrier, step, nextStep }) => {
  // The carrier has an alternative link enabled if it has at least one link config
  const hasAlternativeLinkEnabled = size(get(carrier, "linkConfigs", [])) > 0;

  const [stepName, setStepName] = useState(
    hasAlternativeLinkEnabled ? "select-verification-method" : "login"
  );

  useEffect(() => {
    if (!get(carrier, "defined")) {
      setStepName("manual-account");
    }
  }, [carrier]);

  carrier.image = getCarrierLogo(carrier);
  return (
    <button
      className="flex flex-col gap-y-4 px-8 pt-4 hover:bg-gray-100 focus:bg-gray-100 cursor-pointer w-full text-justify"
      onClick={() => {
        nextStep(stepName);
        setCarrier({ carrier });
      }}
    >
      <Row
        backgroundImageUrl={carrier.image}
        rowHeader={carrier.name}
        rowSubHeader={carrier.domain}
      />
      <hr className=" border-gray-100 w-full" />
    </button>
  );
};

export default Carrier;
