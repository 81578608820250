import React, { useEffect } from "react";
import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import { get } from "lodash";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { getSession } from "../lib/axle";

interface RememberUserProps {
    step: string;
    nextStep: (nextStep: string, noHistory?: boolean) => void;
    loginInformation: Record<string, any>;
    setRememberMe: (rememberMe: boolean) => void;
    posthog: any;
    session: any;
};

const RememberUser = ({
    step,
    nextStep,
    loginInformation,
    setRememberMe,
    posthog,
    session,
}: RememberUserProps) => {
    // PostHog feature flag for Remember Me feature
    const rememberMeFeatureEnabled = useFeatureFlagEnabled('remember-me');

    useEffect(() => {
        const checkSession = async () => {
            posthog.capture("$pageview", {
                step,
                carrier: get(loginInformation, "carrier.id"),
            });

            // Get login result from state
            const result = get(loginInformation, "result");
            const resultDetail = get(loginInformation, "resultDetail");

            // If feature flag is disabled or result is not "link", skip this step and go straight to success
            if (rememberMeFeatureEnabled === false || result !== "link") {
                console.log("Remember Me feature is disabled or result is not 'link', skipping to success");
                setRememberMe(false);
                nextStep("success", true);
                return;
            }

            const returningUser = get(session, "returningUser");
            // If returningUser is "re-link", set rememberMe to true and go to success
            if (returningUser === "re-link") {
                console.log("User is a returning user, setting rememberMe to true");
                setRememberMe(true);
                nextStep("success", true);
            }

        };

        checkSession();
    }, [posthog, rememberMeFeatureEnabled, step, session.id, loginInformation, setRememberMe, nextStep]);

    // Don't render the component if the feature flag is still loading or disabled
    if (rememberMeFeatureEnabled === false || rememberMeFeatureEnabled === undefined) {
        return null;
    }

    const handleSave = () => {
        posthog.capture("remember-user-save", {
            step,
            carrier: get(loginInformation, "carrier.id"),
        });
        setRememberMe(true);
        nextStep("success");
    };

    const handleSkip = () => {
        posthog.capture("remember-user-skip", {
            step,
            carrier: get(loginInformation, "carrier.id"),
        });
        setRememberMe(false);
        nextStep("success");
    };

    return (
        <>
            <div className="flex">
                <div
                    className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center"
                    aria-label="Axle"
                ></div>
                <div
                    style={{
                        backgroundImage: `url("${loginInformation.carrier.image}")`,
                    }}
                    className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center border border-solid border-white box-border transform -translate-x-2"
                    aria-label={loginInformation.carrier.name}
                ></div>
            </div>
            <div className="flex flex-col gap-y-2">
                <h3 className="text-xl text-black font-bold">
                    Save {loginInformation.carrier.name} for next time
                </h3>
                <p className="text-lg text-black">
                    Connect faster to over 1,000 Axle-powered experiences.
                </p>
            </div>
            <div className="flex flex-col gap-y-4 mt-auto">
                <hr className="-mx-8 border-gray-100" />
                <ButtonPrimary
                    name="remember-user-save"
                    type={"submit"}
                    text={"Save"}
                    width={"w-full"}
                    onClick={handleSave}
                />
                <ButtonSecondary
                    name="remember-user-skip"
                    text={"Finish without saving"}
                    onClick={handleSkip}
                    type={"button"}
                />
            </div>
        </>
    );
};

export default RememberUser;