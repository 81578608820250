import { ButtonPrimary } from "../components/Button";
import { stubAccount, updateAccount, updateIgnition } from "../lib/axle";

// External dependencies
import { get, isEmpty, trim } from "lodash";
import { useEffect, useState } from "react";

const validateAccountInput = (
  hasCarrier,
  carrierName,
  firstName,
  lastName,
  setError
) => {
  carrierName = trim(carrierName);
  firstName = trim(firstName);
  lastName = trim(lastName);

  if (!hasCarrier && !carrierName) {
    setError("Please add a carrier!");
    return false;
  }

  if (!firstName) {
    setError("Please enter the policyholder's first name!");
    return false;
  }

  if (!lastName) {
    setError("Please enter the policyholder's last name!");
    return false;
  }

  return true;
};

const ManualAccount = ({
  step,
  nextStep,
  accountInfo,
  setAccountInfo,
  loginInformation,
  setLoginInformation,
  session,
  history,
  setHistory,
  posthog,
}) => {
  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  const ignitionToken = session.id;

  const [carrierName, setCarrierName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (get(loginInformation, "carrier.id")) {
      async function updateIgnitionWithCarrier() {
        const params = {
          carrier: get(loginInformation, "carrier.id"),
        };
        const [updateIgnitionResponse, updateIgnitionError] =
          await updateIgnition(session.id, params);

        if (updateIgnitionError) {
          switch (get(updateIgnitionError, "code")) {
            case 500:
              console.log(JSON.stringify(updateIgnitionError));
              nextStep("axle-error");
              return;
            default:
              nextStep("connection-error");
              return;
          }
        }
      }
      updateIgnitionWithCarrier();
    }
    const isEnhancedEnabled = get(session, "config.manual.enhanced");

    if (isEnhancedEnabled) {
      nextStep("enhanced-manual-policy-document");

      setHistory(history);

      return;
    }
  }, []);

  const hasCarrier = get(loginInformation, "carrier.id") ? true : false;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      !validateAccountInput(
        hasCarrier,
        carrierName,
        firstName,
        lastName,
        setError
      )
    ) {
      return;
    }

    const processDetail = "policy-form";

    console.log("Account doesn't exist, creating account...");

    //TODO: remove once we validate which pathway doesn't set ignitionToken (refer to AXL-4235)
    if (isEmpty(ignitionToken)) {
      console.log(`processDetail: ${processDetail}`);
      console.error("No ignitionToken provided"); //Use this .error to search in Sentry
    }

    // Save account information
    const [stubAccountResponse, stubAccountResponseError] = await stubAccount(
      ignitionToken,
      {
        firstName: firstName,
        lastName: lastName,
        carrier: hasCarrier ? get(loginInformation, "carrier.id") : carrierName,
      },
      processDetail
    );

    if (stubAccountResponseError) {
      switch (get(stubAccountResponseError, "code")) {
        case 500:
          console.log(JSON.stringify(stubAccountResponseError));
          nextStep("axle-error");
          setError(stubAccountResponseError.message);
          return;

        default:
          nextStep("connection-error");
          return;
      }
    }

    // Save policy information
    setAccountInfo({
      id: get(stubAccountResponse, "id", null),
      firstName,
      lastName,
      carrier: hasCarrier ? get(loginInformation, "carrier.id") : carrierName,
    });

    // Set result to manual if not basic
    loginInformation.result === "basic"
      ? setLoginInformation({
          ...loginInformation,
          result: "basic",
          resultDetail: "basic",
        })
      : setLoginInformation({
          ...loginInformation,
          result: "manual",
          resultDetail: "policy-form",
        });

    nextStep("manual-policy-info");
  };

  return (
    <>
      <div className="flex">
        <div
          className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
          aria-label="Axle"
        ></div>
        <div
          style={{
            backgroundImage: `url("${
              hasCarrier
                ? get(loginInformation, "carrier.image")
                : `https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg`
            }")`,
            // backgroundColor: loginInformation.carrier.color,
          }}
          className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
          aria-label={
            hasCarrier
              ? get(loginInformation, "carrier.name")
              : "Unknown carrier"
          }
        ></div>
      </div>
      <div className="flex flex-col gap-y-2">
        <h3 className="text-xl text-black font-bold">
          Enter your policy holder's information
        </h3>
        <p className="text-base text-black">
          {hasCarrier ? (
            <>
              Axle will provide this information if we’re unable to connect with{" "}
              <b>{loginInformation.carrier.name}</b>.
            </>
          ) : (
            <>Axle will use this information to verify your policy details.</>
          )}
        </p>
      </div>
      <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-4 overflow-y-auto">
          {error && (
            <div
              className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
              role="status"
            >
              {" "}
              {error}{" "}
            </div>
          )}
          {!hasCarrier && (
            <input
              placeholder="Carrier"
              value={carrierName}
              className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
              type="text"
              onChange={(e) => setCarrierName(e.target.value)}
            />
          )}
          <input
            placeholder="First name"
            value={firstName}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            placeholder="Last name"
            value={lastName}
            className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
            type="text"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-y-6 mt-auto">
          <hr className="-mx-8 border-gray-100" />
          <ButtonPrimary text={"Continue"} width={"w-full"} type={"submit"} />
        </div>
      </form>
    </>
  );
};

export default ManualAccount;
