import _, { get, size } from "lodash";
import { useEffect, useState } from "react";
import { ButtonPrimary, ButtonSecondary } from "../components/Button";
import CarrierLoader from "./CarrierLoader";
import { processAlternativeLink } from "../lib/axle";
import { validateLinkConfigUserFields } from "../lib/utility";
import { handleProcessAlternativeLinkError } from "../lib/errorHandlersUtility";

const PolicyLookup = ({
  step,
  nextStep,
  setAccountInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  linkAttempts,
  setLinkAttempts,
  session,
  setLinkError,
  posthog,
  setShowNav,
}) => {
  const [userFields, setUserFields] = useState({ policyType: "auto" }); // TEMPORARY: Default to policyType 'auto' for now
  const [promiseInProgress, setPromiseInProgress] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  let loadingSteps = [
    "Establishing a secure connection",
    "Contacting your carrier",
    "Retrieving policy information",
  ];

  const carrier = get(loginInformation, "carrier.id");
  const ignitionToken = session.id;
  const carrierConfig = session.carriers.find(
    (carrierConfig) => carrierConfig.id === carrier
  );
  const isManualEnabled = get(session, "config.manual.enabled");
  const isEnhancedEnabled = get(session, "config.manual.enhanced");
  const carrierLinkConfigs = get(carrierConfig, "linkConfigs", []);

  const lpLinkConfig = carrierLinkConfigs.find(
    (linkConfig) => linkConfig.type === "LP"
  );
  const userInputFieldsConfig = get(lpLinkConfig, "userInputFields", []);

  if (size(userInputFieldsConfig) === 0) {
    console.error("No user input fields config found for carrier", carrier);
    nextStep("carrier-error");
    return;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validate user input fields
    const [isValid, errorMessage] = validateLinkConfigUserFields(
      userFields,
      userInputFieldsConfig
    );
    if (!isValid) {
      setError(errorMessage);
      return;
    }

    // Track policy lookup submit
    posthog.capture("policy-lookup-submit", {
      step,
      carrier,
      linkAttempts: get(linkAttempts, carrier, 0) + 1,
    });

    setPromiseInProgress(true);
    // Remove the nav bar while loading
    setShowNav(false);

    // Call processAlternativeLink handler
    const [
      processAlternativeLinkResponse,
      processAlternativeLinkResponseError,
    ] = await processAlternativeLink(ignitionToken, carrier, userFields);

    // Restore the nav bar
    setShowNav(true);

    if (processAlternativeLinkResponseError) {
      handleProcessAlternativeLinkError({
        error: processAlternativeLinkResponseError,
        linkAttempts,
        setLinkAttempts,
        setError,
        nextStep,
        setPromiseInProgress,
        setLinkError,
        carrier,
      });
      return;
    }

    // We have a successful response from Alternative Link
    const accountId = get(processAlternativeLinkResponse, "accountId", "");
    const policyId = get(processAlternativeLinkResponse, "policyId", "");

    // Setting account and policy info
    setAccountInfo({ id: accountId });
    setPolicyInfo({ id: policyId });

    nextStep("success");
    return;
  };

  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Looking up your policy"
          loadingSteps={loadingSteps}
          footerText={"We are currently looking up your policy information."}
        />
      ) : (
        <>
          <div className="grow flex flex-col gap-8">
            <div className="flex">
              <div className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"></div>
              <div
                style={{
                  backgroundImage: `url("${loginInformation.carrier.image}")`,
                }}
                className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
                aria-label={loginInformation.carrier.name}
              ></div>
            </div>
            <div className="flex flex-col gap-y-2">
              <h3 className="text-xl text-black font-bold">
                Enter your policy information
              </h3>
              <p className="text-lg text-black">
                Providing your <b>{loginInformation.carrier.name}</b> policy
                information enables Axle to securely look up your policy.
              </p>
            </div>
            <form className="flex flex-col gap-y-8" onSubmit={onSubmit}>
              <div className="flex flex-col gap-y-6">
                {error && (
                  <div
                    className=" text-red-900 text-sm rounded-sm bg-red-100 p-3 -mb-1"
                    role="status"
                  >
                    {" "}
                    {error}{" "}
                  </div>
                )}

                {userInputFieldsConfig.map((item) => (
                  <input
                    placeholder={item.placeholder}
                    value={userFields[item.value]}
                    className="border border-solid border-black p-3 text-base rounded-sm text-black placeholder-black"
                    type={item.type}
                    onChange={(e) =>
                      setUserFields({
                        ...userFields,
                        [item.value]: e.target.value,
                      })
                    }
                  />
                ))}
              </div>
              <div className="flex flex-col gap-y-4">
                <ButtonPrimary
                  text={"Look up"}
                  width={"w-full"}
                  type={"submit"}
                />

                <ButtonSecondary
                  onClick={() => {
                    if (!isManualEnabled) {
                      nextStep("login");
                    } else if (isEnhancedEnabled) {
                      nextStep("enhanced-manual-policy-document");
                    } else {
                      nextStep("manual-policy-document");
                    }
                  }}
                  text={
                    isManualEnabled ? "Verify manually" : "Log in to my account"
                  }
                />
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default PolicyLookup;
