import _, { get } from "lodash";
import { PostHog } from "posthog-js/react";

/**
 * Calls identify, alias, and group on the Posthog instance to identify the user and group them by client
 * @param {*} posthog - Posthog instance
 * @param {*} session - Ignition session - Must contain an id and a user.id
 * @param {*} client - Client object - Must contain an id, displayName, and entity
 */
export const identifyPosthogUser = (posthog: PostHog, session: any, client: any) => {
  const userId = get(session, "user.id");
  const sessionId = get(session, "id");
  const clientId = get(client, "id");
  const clientDisplayName = get(client, "displayName");
  const clientEntity = get(client, "entity");

  // Util for debugging
  // console.log("Initializing posthog user with: ", {
  //   userId,
  //   sessionId,
  //   clientId,
  //   clientDisplayName,
  //   clientEntity
  // })

  // Identify Posthog user
  if (userId && clientId) {
    // If user.id and client.id are available, identify user with both
    posthog?.identify(
      `${userId}-${clientId}`,
    );
    // Assign the ignition session as an alias to the user
    posthog?.alias(sessionId);
  } else {
    posthog?.identify(sessionId);
  }

  posthog?.group("client", clientId, {
    name: clientDisplayName,
    entity: clientEntity,
    id: clientId,
    manualEnabled: get(session, "config.manual.enabled"),
  });
}