export const COOKIE_NAMES = {
    "rux": "axl_rux" // Cookie which stores the user's RUX token
}

// Local storage keys (matching cookie names for consistency)
export const STORAGE_KEYS = {
    "rux": "axl_rux_storage" // Local storage key for the RUX token
}

/*
    Cookie Management Functions
*/
/**
* Sets a cookie with the given name, value, and options
* Note: This function will delete the existing cookie with the same name before setting a new one.
* @param name - The name of the cookie
* @param value - The value to store in the cookie
    * @param options - Cookie options like expires, path, domain, secure, etc.
*/
export function setCookie(name: string, value: string, options: Record<string, any> = {}) {
    // Convert options to cookie string format
    const cookieOptions = Object.entries(options)
        .map(([key, value]) => {
            // Convert camelCase to kebab-case
            const formattedKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();

            // For boolean values like httpOnly, just include the key
            if (typeof value === 'boolean' && value) {
                return formattedKey;
            }

            return `${formattedKey}=${value}`;
        })
        .join('; ');

    // Set the cookie
    const cookieString = `${name}=${value}; ${cookieOptions}`;

    const domain = options.domain;

    deleteCookie(name, domain); // Delete the existing cookie if it exists
    document.cookie = cookieString;
}

/**
 * Parses and finds a specific cookie
 * @param name - The name of the cookie to retrieve
 * @returns The value of the cookie or undefined if not found
 */
export const getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        const part = parts.pop();
        return part ? part.split(';').shift() : undefined;
    }
    return undefined;
}

/**
 *  Deletes a cookie by setting its expiration date to the past
 * @param name - The name of the cookie to delete
 * @param domain - The domain of the cookie (optional)
 */
export function deleteCookie(name: string, domain?: string) {
    // If a domain is provided, set the domain in the cookie options
    const domainOption = domain ? `; domain=${domain}` : '';

    // Set the cookie with an expired date to delete it
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/${domainOption}`;
}

/*
    Local Storage Management Functions
*/
/**
 * Sets a value to local storage
 * @param key - The key to use for storage
 * @param value - The value to store
 */
export const setInStorage = (key: string, value: string): void => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        console.error('Error setting localStorage:', error);
    }
}

/**
 * Get value from local storage
 * @param key - The key to retrieve from local storage
 * @returns The stored value or undefined if not found
 */
export const getFromStorage = (key: string): string | undefined => {
    try {
        const value = localStorage.getItem(key);
        return value || undefined;
    } catch (error) {
        console.error('Error accessing localStorage:', error);
        return undefined;
    }
}

/**
 * Remove value from local storage
 * @param key - The key to remove from local storage
 */
export const removeFromStorage = (key: string): void => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing from localStorage:', error);
    }
}

/*
    Combined Cookie and Local Storage Management Functions
*/
/**
 * Sets a value to both cookie and local storage
 * @param name - The name for the cookie
 * @param value - The value to store
 * @param options - Cookie options
 */
export const setValue = (name: keyof typeof COOKIE_NAMES, value: string, options: Record<string, any> = {}): void => {
    // Set to cookie
    const cookieKey = COOKIE_NAMES[name as keyof typeof COOKIE_NAMES] || name;
    setCookie(cookieKey, value, options);

    // Set to local storage using the corresponding storage key
    const storageKey = STORAGE_KEYS[name as keyof typeof STORAGE_KEYS] || name;
    setInStorage(storageKey, value);
}

/**
 * Get value from either cookie or local storage
 * @param name - The name/key to look for
 * @returns The value from cookie or local storage or undefined if not found
 */
export const getValue = (name: keyof typeof COOKIE_NAMES): string | undefined => {
    // First try to get from cookie
    const cookieKey = COOKIE_NAMES[name as keyof typeof COOKIE_NAMES] || name;
    const cookieValue = getCookie(cookieKey);
    if (cookieValue) return cookieValue;

    // If not in cookie, try local storage
    const storageKey = STORAGE_KEYS[name as keyof typeof STORAGE_KEYS] || name;
    return getFromStorage(storageKey);
}

/**
 * Remove value from both cookie and local storage
 * @param name - The name/key to remove
 * @param domain - Optional domain for cookie deletion
 */
export const removeValue = (name: keyof typeof COOKIE_NAMES, domain?: string): void => {
    // Remove from cookie
    const cookieKey = COOKIE_NAMES[name as keyof typeof COOKIE_NAMES] || name;
    deleteCookie(cookieKey, domain);

    // Remove from local storage
    const storageKey = STORAGE_KEYS[name as keyof typeof STORAGE_KEYS] || name;
    removeFromStorage(storageKey);
}