import imageCompression from "browser-image-compression";
import _, { get, toLower } from "lodash";
import { useState } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import { linkAccount } from "./axle";
import { MAX_FILE_SIZE, MAX_MEGAPIXELS } from "./constants";

export const constructUriWithSearchParams = (urlAsString, paramsToAdd) => {
  let urlAsObject = new URL(urlAsString);

  for (const [key, value] of Object.entries(paramsToAdd)) {
    urlAsObject.searchParams.append(key, value);
  }

  return urlAsObject.toString();
};

export const generateLinkErrorMessage = (linkError) => {
  return `The Axle service could not ${linkError} for the specified carrier.`;
};

export const generateManualErrorMessage = (manualError) => {
  return `The Axle service could not ${manualError} the uploaded document`;
};

export const generateIgnitionErrorMessage = (ignitionError) => {
  return `Invalid token provided. Please ${ignitionError}`;
};

export const checkZipCode = (str) => {
  // Check if the string consists of exactly 5 numeric characters
  return /^\d{5}$/.test(str);
};

export const checkIgnitionToken = (ignitionToken) => {
  const ignitionTokenValidation = {
    message: "",
    status: true,
  };
  // Check if the string starts with "ign_"
  const startsWithIgn = _.startsWith(ignitionToken, "ign_");

  if (!startsWithIgn) {
    _.set(
      ignitionTokenValidation,
      "message",
      generateIgnitionErrorMessage("ensure ignition token starts with 'ign_'")
    );
    _.set(ignitionTokenValidation, "status", false);

    return ignitionTokenValidation;
  }

  // Check if the string contains exactly 25 characters
  const validLength = _.size(ignitionToken) === 25;

  if (!validLength) {
    _.set(
      ignitionTokenValidation,
      "message",
      generateIgnitionErrorMessage(
        "ensure ignition token is 25 characters long"
      )
    );
    _.set(ignitionTokenValidation, "status", false);
  }

  return ignitionTokenValidation;
};

export const getUserAndMetadataFromQueryParams = (obj, userKeys) => {
  const extractedUserItems = _.pick(obj, userKeys);
  const extractedMetadataKeys = _.difference(_.keys(obj), userKeys);
  const extractedMetadataItems = _.pick(obj, extractedMetadataKeys);

  // Parse phone number
  if (extractedUserItems.phone) {
    extractedUserItems.phone = parsePhoneNumber(
      extractedUserItems.phone,
      "US"
    )?.number;
  }

  // Return extracted user fields
  return {
    userQueryParams: extractedUserItems,
    metadataQueryParams: extractedMetadataItems,
  };
};

/**
 * Compress an image file to fit strictly within both:
 *   1) MAX_MEGAPIXELS by scaling resolution if necessary
 *   2) MAX_FILE_SIZE (in MB) by performing a binary search on the quality
 *
 * @param {File} file        The original image file.
 * @param {string} mimeType  The target MIME type (e.g., 'image/jpeg', 'image/png').
 * @returns {Promise<File>}   A promise that resolves to a compressed File object.
 */
export const compressImage = async (file, mimeType) => {
  console.log(
    `Compressing image to <= ${MAX_MEGAPIXELS} MP and <= ${MAX_FILE_SIZE} MB...`
  );

  try {
    const sizeInMB = file.size / (1024 * 1024);
    console.log(`Original file size: ${sizeInMB.toFixed(2)} MB`);

    if (sizeInMB === 0) {
      console.warn("Empty file found...");
    }

    // Get image dimensions
    const [img] = await imageCompression.drawFileInCanvas(file);
    const width = img.width;
    const height = img.height;
    const currentMegaPixels = (width * height) / 1_000_000;
    console.log(`Original megapixels: ${currentMegaPixels.toFixed(2)}`);

    // Check if file already meets both constraints
    if (currentMegaPixels <= MAX_MEGAPIXELS && sizeInMB <= MAX_FILE_SIZE) {
      console.log("Image already under threshold; returning original file.");
      return file;
    }

    // Reduce dimensions so we don't exceed max MP
    let scaleFactor = 1;
    if (currentMegaPixels > MAX_MEGAPIXELS) {
      scaleFactor = Math.sqrt(MAX_MEGAPIXELS / currentMegaPixels);
    }
    const targetWidth = Math.round(width * scaleFactor);
    const targetHeight = Math.round(height * scaleFactor);

    // Helper function that compresses at a given quality
    async function compressAtQuality(quality) {
      const options = {
        fileType: mimeType,
        initialQuality: quality,
        maxWidthOrHeight: Math.max(targetWidth, targetHeight),
        maxIteration: 1,
        useWebWorker: true,
      };
      const compressed = await imageCompression(file, options);
      return compressed;
    }

    // Binary search to find the highest quality that produces <= MAX_FILE_SIZE MB
    let lowerQuality = 0.01; // Minimum reasonable quality
    let upperQuality = 1.0; // Maximum (best) quality
    let bestFile = null;
    let bestFileSizeMB = Infinity;

    const MAX_ITERATIONS = 8;
    for (let i = 0; i < MAX_ITERATIONS; i++) {
      const midQuality = (lowerQuality + upperQuality) / 2;
      const testFile = await compressAtQuality(midQuality);
      const testFileSizeMB = testFile.size / (1024 * 1024);

      // If the file fits under the max size, try to go higher (improve quality).
      if (testFileSizeMB <= MAX_FILE_SIZE) {
        bestFile = testFile;
        bestFileSizeMB = testFileSizeMB;
        lowerQuality = midQuality;
      } else {
        // Otherwise, go lower to get a smaller file.
        upperQuality = midQuality;
      }

      // Stop early if we're close enough to the target (e.g., within 0.01 MB)
      if (Math.abs(testFileSizeMB - MAX_FILE_SIZE) < 0.01) {
        break;
      }
    }

    // If after binary search we never found a file <= max size, bestFile might remain null
    if (!bestFile) {
      // Use the final attempt from compressAtQuality(upperQuality) or just fallback
      console.warn(
        "Could not get below the target file size, returning best attempt possible."
      );
      bestFile = await compressAtQuality(lowerQuality);
      bestFileSizeMB = bestFile.size / (1024 * 1024);
    }

    console.log(`Final compressed size: ${bestFileSizeMB.toFixed(2)} MB`);
    return bestFile;
  } catch (error) {
    console.error("Error compressing image:", error);
    throw error;
  }
};
// Define a custom hook that can be used by both EnterMfa and Login components
// This hook handles getAccount logic, including retries and error handling
export const useAccountHandler = (
  {
    ignitionToken,
    setAccountInfo,
    loginInformation,
    nextStep,
    setLinkError,
    setShowNav,
    retryGetAccountAttempts,
  },
  setLoginInformation
) => {
  const retryGetAccount = async () => {
    if (retryGetAccountAttempts < 1) {
      retryGetAccountAttempts++;
      console.log("Received an error from getAccount, retrying...");
      await getAccount();
    } else {
      setLinkError("retrieve account details");
      nextStep("carrier-error");
    }
  };

  const handleUnsuccessfulGetAccount = async (error) => {
    switch (get(error, "code")) {
      case 400:
        if (
          error.message ===
          "Sorry, your insurance account or policy has not been set up for online access."
        ) {
          nextStep("account-pending");
        } else {
          console.error("Unknown 400 error from getAccount: ", error);
          setLinkError("retrieve account details");
          nextStep("carrier-error");
        }
        break;
      case 503:
        nextStep("carrier-maintenance");
        break;
      case 401:
      case 403:
      case 500:
        console.log(`${get(error, "code")} error from getAccount: `, error);
        await retryGetAccount();
        break;
      default:
        console.error("Unknown error from getAccount: ", error);
        await retryGetAccount();
    }
  };

  const getAccount = async (isReturningLink) => {
    // Fetch account
    const [linkAccountResponse, linkAccountError] = await linkAccount(
      ignitionToken
    );

    if (linkAccountError) {
      // Handle unsuccessful getAccount
      await handleUnsuccessfulGetAccount(linkAccountError);

      // Restore the nav bar
      setShowNav(true);
    } else {
      // If no compatible policies on account send to "no-policies"
      if (get(linkAccountResponse, "policies.length", 0) === 0) {
        nextStep("no-policies", isReturningLink);
        return;
      }

      // Save account info to state
      setAccountInfo(linkAccountResponse);

      // setLoginInformation is not passed in at the EnterMfa step, so only perform this logic when the previous step was Login
      if (setLoginInformation) {
        // Set login result to link
        setLoginInformation({
          ...loginInformation,
          result: "link",
          resultDetail: "user-portal",
        });
      }

      // Go to policy selection step
      nextStep("confirm", isReturningLink);

      // Restore the nav bar
      setShowNav(true);
    }
  };

  return getAccount;
};

// Function performs input validation on the user input fields listed in a carrier's linkConfig
// This includes fields such as policy number, last 5 of VIN, postal code, etc.
export const validateLinkConfigUserFields = (
  userFields,
  userInputFieldsConfig
) => {
  let isValid = true;
  let errorMessage = "";
  for (const inputField of userInputFieldsConfig) {
    const inputFieldValue = userFields[inputField.value];
    // check if input field is empty
    if (!inputFieldValue) {
      isValid = false;
      errorMessage = `Please add ${toLower(inputField.placeholder)}!`;
      return [isValid, errorMessage];
    }

    // Check if input field is the correct length
    if (
      inputField.minLength &&
      inputField.maxLength &&
      !(inputField.minLength <= inputFieldValue.length <= inputField.maxLength)
    ) {
      isValid = false;
      errorMessage = `Please enter a valid ${toLower(inputField.placeholder)}.`;
      return [isValid, errorMessage];
    } else if (
      // If only minLength is available, check if input field is too short
      inputField.minLength &&
      !(inputField.minLength <= inputFieldValue.length)
    ) {
      isValid = false;
      errorMessage = `Please enter a valid ${toLower(inputField.placeholder)}.`;
      return [isValid, errorMessage];
    } else if (
      // If only maxLength is available, check if input field is too long
      inputField.maxLength &&
      !(inputFieldValue.length <= inputField.maxLength)
    ) {
      isValid = false;
      errorMessage = `Please enter a valid ${toLower(inputField.placeholder)}.`;
      return [isValid, errorMessage];
    }
  }

  return [isValid, errorMessage];
};
