import { RadioGroup } from '@headlessui/react';
import { get } from 'lodash';
import React from 'react';
import { RadioButton } from './Button';

interface AccountProps {
    account: {
        carrier: string;
        firstName?: string;
        lastName?: string;
        email?: string;
    };
    carrier: {
        id: string;
        name: string;
        hasImage?: boolean;
    };
    onClick?: () => void;
}

const Account: React.FC<AccountProps> = ({ account, carrier, onClick }) => {
    const fullName = [account.firstName, account.lastName]
        .filter(Boolean)
        .join(' ');

    // Use the same logic for carrier images as in Carrier.js
    const logoBucket = "https://axle-labs-assets.s3.amazonaws.com/carrierLogos/";
    const carrierImage = carrier.hasImage
        ? logoBucket + carrier.id + ".svg"
        : logoBucket + "generic.svg";

    return (
        <RadioGroup.Option
            onClick={onClick}
            key={carrier.id}
            value={account}
            className={({ active, checked }) =>
                `flex items-center text-black rounded group ${active
                    ? "text-white border border-solid border-black bg-black p-4 filter shadow transition-colors cursor-pointer"
                    : ""
                }
                ${checked
                    ? "text-white border border-solid border-black bg-black p-4 filter shadow transition-colors cursor-pointer"
                    : "hover:text-white border border-solid border-black bg-white hover:bg-black p-4 filter shadow transition-colors cursor-pointer"
                }
                `
            }
        >
            {({ active, checked }) => (
                <>
                    <div className="mr-4">
                        <div
                            style={{
                                backgroundImage: `url("${carrierImage}")`,
                            }}
                            className="rounded-full h-12 w-12 bg-cover bg-center"
                            aria-label={carrier.name}
                        ></div>
                    </div>
                    <div className="flex-grow">
                        <h3 className="text-base font-bold">{carrier.name}</h3>
                        {/* If account email is available, use that. If not, use user firstName */}
                        {account.email
                            ? <p className="text-sm text-gray-500">{account.email}</p>
                            : (account.firstName || account.lastName) && (
                                <p className="text-sm text-gray-500">
                                    {[account.firstName, account.lastName].filter(Boolean).join(' ')}
                                </p>
                            )
                        }
                    </div>
                    <div className="flex">
                        {checked ? (
                            <RadioButton checked className="h-6 w-6" />
                        ) : (
                            <RadioButton active className="h-6 w-6" />
                        )}
                    </div>
                </>
            )}
        </RadioGroup.Option>
    );
};

export default Account;