import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/browser";

let options = {
  api_host: null,
  capture_pageview: false,
  capture_pageleave: false,
  session_recording: {
    // `maskInputFn` only applies to selected elements, so make sure to set this to true if you want this to work globally
    maskAllInputs: true,
    maskInputFn: (text, element) => {
      if (element?.attributes["type"]?.value === "search") {
        // If this is a search input, don't mask it
        return text;
      }
      // Otherwise, mask it with asterisks
      return "*".repeat(text.length);
    },
    maskCapturedNetworkRequestFn: (request) => {
      request.requestBody = request.requestBody?.replace(
        /"username":\s*"[^"]*"/g,
        '"username": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"username":\s*"[^"]*"/g,
        '"username": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"password":\s*"[^"]*"/g,
        '"password": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"password":\s*"[^"]*"/g,
        '"password": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"authCode":\s*"[^"]*"/g,
        '"authCode": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"authCode":\s*"[^"]*"/g,
        '"authCode": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"firstName":\s*"[^"]*"/g,
        '"firstName": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"firstName":\s*"[^"]*"/g,
        '"firstName": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"lastName":\s*"[^"]*"/g,
        '"lastName": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"lastName":\s*"[^"]*"/g,
        '"lastName": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"policyNumber":\s*"[^"]*"/g,
        '"policyNumber": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"policyNumber":\s*"[^"]*"/g,
        '"policyNumber": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"policyHolder":\s*"[^"]*"/g,
        '"policyHolder": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"policyHolder":\s*"[^"]*"/g,
        '"policyHolder": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"email":\s*"[^"]*"/g,
        '"email": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"email":\s*"[^"]*"/g,
        '"email": "redacted"'
      );

      request.requestBody = request.requestBody?.replace(
        /"phone":\s*"[^"]*"/g,
        '"phone": "redacted"'
      );
      request.responseBody = request.responseBody?.replace(
        /"phone":\s*"[^"]*"/g,
        '"phone": "redacted"'
      );

      return request;
    },
  },
};
let posthog_key = null;

// Set Posthog options
switch (process.env.REACT_APP_STAGE) {
  case "prod":
    options.api_host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST_PROD;
    posthog_key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY_PROD;
    break;
  case "dev":
    options.api_host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST_DEV;
    posthog_key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY_DEV;
    break;
  case "sandbox":
  default:
    // POSTHOG will be set to null in sandbox deployed, but use local environment variables during unit tests
    options.api_host = process.env.REACT_APP_PUBLIC_POSTHOG_HOST_SANDBOX || null;
    posthog_key = process.env.REACT_APP_PUBLIC_POSTHOG_KEY_SANDBOX || null;
}

Sentry.init({
  environment: process.env.REACT_APP_STAGE,
  dsn: `https://${process.env.REACT_APP_SENTRY_IO_DSN_HASH}.ingest.us.sentry.io/${process.env.REACT_APP_SENTRY_IO_PROJECT_ID}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    // Sentry.replayIntegration(), // Only available in Business Plan
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider apiKey={posthog_key} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
