import { get, isEmpty } from "lodash";
import { useRef } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import CarrierLoader from "../steps/CarrierLoader";
import { ButtonPrimary } from "./Button";
import {
  getUploadHeader,
  getAcceptedDocumentDescriptions,
} from "../lib/manualDocumentUtility";
import React from "react";
import { CURRENTLY_PROCESSING_DOCUMENT } from "../constants";

/**
 * Combine accepted types specified in the client config into a string accepted by an <input> element for the `accept` attribute.
 * @param {*} config
 * @returns
 */
const combineAcceptedTypesString = (config: Record<string, any>): string => {
  let combinedAcceptedTypesAsString = "";

  const acceptedTypes: string[] = get(
    config,
    "documentation.acceptedTypes",
    []
  );
  if (!isEmpty(acceptedTypes)) {
    combinedAcceptedTypesAsString = get(
      config,
      "documentation.acceptedTypes",
      []
    )
      .map((type: string) => {
        if (type === "pdf") return "application/pdf";
        if (type === "image") return "image/jpeg,image/png,image/gif";
        return undefined;
      })
      .filter((type: undefined | string) => type)
      .join(",");
  }

  return combinedAcceptedTypesAsString;
};

export const DocumentUpload = ({
  session,
  loginInformation,
  uploadFile,
  loadingSteps,
}: {
  session: Record<string, any>;
  loginInformation: Record<string, any>;
  uploadFile: Function;
  loadingSteps: Record<string, any>;
}) => {
  const { promiseInProgress } = usePromiseTracker({ area: "carrier-loader" });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadOrCapture = () => {
    // Trigger .click() on the file input element which will show the file picker
    fileInputRef.current?.click();
  };

  // Handle the file(s) selected by the user
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const fileData = reader.result;
          const filePath = file.path || file.name;

          // Pass the actual file data and path directly to uploadFile
          uploadFile(fileData, filePath);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const hasCarrier = get(loginInformation, "carrier.id") ? true : false;
  return (
    <>
      {promiseInProgress ? (
        <CarrierLoader
          loginInformation={loginInformation}
          loadingHeader="Upload policy document"
          loadingSteps={loadingSteps}
          processDocument={true}
          footerText={CURRENTLY_PROCESSING_DOCUMENT}
        />
      ) : (
        <>
          <div className="flex">
            <div
              className="inline-block rounded-full h-12 w-12 bg-black bg-logo-svg bg-5/8 bg-no-repeat bg-center box-content border border-solid border-white z-10"
              aria-label="Axle"
            ></div>
            <div
              style={{
                backgroundImage: `url("${
                  hasCarrier
                    ? get(loginInformation, "carrier.image")
                    : `https://axle-labs-assets.s3.amazonaws.com/carrierLogos/generic.svg`
                }")`,
              }}
              className="inline-block rounded-full h-12 w-12 bg-black bg-cover bg-center transform -translate-x-2"
              aria-label={
                hasCarrier
                  ? get(loginInformation, "carrier.name")
                  : "Unknown carrier"
              }
            ></div>
          </div>
          <div className="flex flex-col gap-y-3">
            <h3 className="text-xl text-black font-bold">
              {getUploadHeader(session)}
            </h3>
            <p className="text-lg text-base text-black">
              PDFs or images with clearly readable text provide the best
              results.
            </p>
          </div>

          <div className="border-2 border-dashed border-gray-300 rounded-md p-6 bg-[#F4F4F5]">
            {getAcceptedDocumentDescriptions(session)}
          </div>
          <input
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
            accept={combineAcceptedTypesString(get(session, "config"))}
            onChange={handleFileChange}
          />
          <div className="flex flex-col gap-y-3 mt-auto">
            <hr className="-mx-8 border-gray-100" />

            <ButtonPrimary
              text={"Upload or capture"}
              onClick={handleUploadOrCapture}
              width={"w-full"}
              name="uploadOrCapture"
              type="button"
            />
          </div>
        </>
      )}
    </>
  );
};
