import { get, isEmpty } from "lodash";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { DocumentUpload } from "../components/DocumentUpload";
import {
  generateSignedUrl,
  uploadDocument,
  upsertPolicyElements,
} from "../lib/axle";

const ManualPolicyDocument = ({
  step,
  nextStep,
  accountInfo,
  setAccountInfo,
  policyInfo,
  setPolicyInfo,
  loginInformation,
  setLoginInformation,
  session,
  posthog,
  setManualError,
  setShowNav,
}) => {
  const ignitionToken = session.id;

  let loadingSteps = ["Uploading your document"];

  const uploadWrapper = async (currentFileData, currentFilePath) => {
    const updatedFileData = currentFileData;
    const updatedFilePath = currentFilePath;

    if (!updatedFileData) {
      alert("Please upload a document!");
      return;
    }

    // Hide nav buttons while CarrierLoader is rendered for accessibility
    setShowNav(false);

    const [signedUrlResponse, signedUrlResponseError] = await generateSignedUrl(
      ignitionToken,
      policyInfo.id,
      updatedFilePath
    );

    if (signedUrlResponseError) {
      switch (get(signedUrlResponseError, "code")) {
        case 500:
          setManualError(get(signedUrlResponseError, "message"));
          nextStep("manual-document-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    if (isEmpty(get(signedUrlResponse, "signedUrl"))) {
      console.error("Signed URL is empty");
      setManualError("process");
      nextStep("manual-document-error");
      return;
    }

    const [documentUploadResponse, documentUploadResponseError] =
      await uploadDocument(signedUrlResponse.signedUrl, updatedFileData);

    if (documentUploadResponseError) {
      switch (get(documentUploadResponseError, "code")) {
        case 500:
          setManualError(documentUploadResponseError.message);
          nextStep("manual-document-error");
          return;
        default:
          nextStep("connection-error");
          return;
      }
    }

    const documentKey = signedUrlResponse.objectKey;
    let documentsObject = [
      {
        source: "user",
        name: updatedFilePath,
        key: documentKey,
        createdAt: new Date().toISOString(),
        type: null,
        effectiveDate: null,
        issuedDate: null,
      },
    ];

    let policyId = policyInfo.id;
    let accountId = accountInfo.id;

    setLoginInformation({
      ...loginInformation,
      result: "manual",
      resultDetail: "policy-form",
    });

    // Setting account and policy info
    setAccountInfo({ id: accountId });
    setPolicyInfo({ id: policyId });

    // Update policy with document
    const [updatedPolicy, updatedPolicyError] = await upsertPolicyElements(
      ignitionToken,
      policyId,
      {
        account: accountId,
        documents: documentsObject,
      }
    );

    if (updatedPolicyError) {
      switch (get(updatedPolicyError, "code")) {
        case 500:
          setManualError(updatedPolicyError.message);
          nextStep("manual-document-error");
          return;

        default:
          nextStep("connection-error");
          return;
      }
    }

    nextStep("remember-user");
    return;
  };

  const uploadFile = async (currentFileData, currentFilePath) => {
    await trackPromise(
      uploadWrapper(currentFileData, currentFilePath),
      "carrier-loader"
    );

    setShowNav(true);
  };

  useEffect(() => {
    posthog.capture("$pageview", { step });
  }, [posthog]);

  return (
    <>
      <DocumentUpload
        session={session}
        loginInformation={loginInformation}
        uploadFile={uploadFile}
        loadingSteps={loadingSteps}
      />
    </>
  );
};

export default ManualPolicyDocument;
