
export const IGNITION_BACKEND_LOCAL = false;
export const DMV_LOCAL = false;

export const genericClientLogoUrl =
  "https://axle-labs-assets.s3.amazonaws.com/clientLogos/generic.svg";

// 40 megapixels is the max image size limit for Google Document AI
export const MAX_MEGAPIXELS = 40;

// 5MB is the max file size limit for Anthropic's API. However, we need to reduce the max file size to 3.75MB to account for the ~33% increase in file size due to base64 encoding.
export const MAX_FILE_SIZE = 3.75;

export const basePermissions: {
  order: number;
  name: string;
  icon: string;
  value: string;
}[] = [
    {
      order: 1,
      name: "Policy Type",
      icon: "type",
      value: "type",
    },
    {
      order: 2,
      name: "Policy Status",
      icon: "checkmark",
      value: "isActive",
    },
    {
      order: 3,
      name: "Policy Number",
      icon: "policyNo",
      value: "policyNumber",
    },
    {
      order: 4,
      name: "Effective Date",
      icon: "calendar",
      value: "effectiveDate",
    },
    {
      order: 5,
      name: "Expiration Date",
      icon: "calendar",
      value: "expirationDate",
    },
    {
      order: 6,
      name: "Insureds",
      icon: "profile",
      value: "insureds",
    },
    {
      order: 8,
      name: "Coverages",
      icon: "shield",
      value: "coverages",
    },
    {
      order: 9,
      name: "Documents",
      icon: "document",
      value: "documents",
    },
  ];


export const returningUserEnums = [
  "re-link",
  "new-account",
  "new-user",
] as const;

export type ReturningUserEnum = typeof returningUserEnums[number];